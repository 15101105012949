/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const LeadershipRoles: React.FC = () => {
  const [leadershipRolesData, setLeadershipRolesData] = useState<any>({});

  useEffect(() => {
    async function fetchLeadershipRolesData() {
      const wfService = new Metadata();
      const response = await wfService.getLeadershipRoles();
      setLeadershipRolesData(response);
    }
    fetchLeadershipRolesData();
  }, []);

  if (!leadershipRolesData) {
    return <Loading />;
  }

  return (
    <div>
      {!!leadershipRolesData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>Leadership Roles Page</h3>
          <ul>
            {leadershipRolesData.map((item: any) => (
              <div key={item.id}>
                <li className="mb-0 last:mb-4">
                  {`Id: ${item.id}`} - {`Value: ${item.value}`} -{' '}
                  {`OrganizationType: ${item?.detail?.OrganizationType}`} -{' '}
                  {`TermLength: ${item?.detail?.TermLength}`} -{' '}
                  {`TermOfAccessBefore: ${item?.detail?.TermOfAccessBefore}`} -{' '}
                  {`TermOfAccessAfter: ${item?.detail?.TermOfAccessAfter}`} -{' '}
                  {`StartDate: ${item?.detail?.StartDate}`} -{' '}
                  {`EndDate: ${item?.detail?.EndDate}`} -{' '}
                  {`MustBeMembership: ${item?.detail?.MustBeMembership}`} -{' '}
                  {`IsOfficer: ${item?.detail?.IsOfficer}`} -{' '}
                  {`RestrictPerOrganizationFlag: ${item?.detail?.RestrictPerOrganizationFlag}`}{' '}
                  - {`DISUseFlag: ${item?.detail?.DISUseFlag}`} -{' '}
                  {`OfficerManagement: ${item?.detail?.OfficerManagement}`}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LeadershipRoles;
